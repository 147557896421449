
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { AppProfile } from '../../../../types/App'

export default Vue.extend<Data, Methods, Computed>({
  name: 'UrgentNoticeView',
  data() {
    return {
      timer: undefined,
    }
  },
  computed: {
    ...mapGetters('ProfileStore', ['getProfile']),
  },
  created() {
    history.pushState(null, 'null', location.href)
    this.$nextTick(() => {
      window.addEventListener('popstate', this.handlePopstate)
    })
    this.timer = setInterval(() => {
      const { useYN, startDt, endDt } = this.getProfile.noticeMaintenance
      if (useYN === 'N' || !this.$moment().isBetween(startDt, endDt)) {
        this.$router.push('/')
      }
    }, this.$urgentNoticeTime)
  },
  beforeDestroy() {
    clearTimeout(this.timer)
    this.timer = undefined
  },
  methods: {
    handlePopstate(e: PopStateEvent) {
      history.go(1)
    },
  },
})

interface Data {
  timer: number | undefined
}
interface Computed {
  getProfile: AppProfile
}
interface Methods {
  handlePopstate: (e: PopStateEvent) => void
}
