
import Vue from 'vue'

import UrgentNotice from '@/components/views/urgentNotice/UrgentNotice.vue'
export default Vue.extend<Data, Computed, Methods>({
  components: { UrgentNotice },
})

interface Data {}
interface Computed {}
interface Methods {}
